import { createSlice } from '@reduxjs/toolkit';
import { Dashboard } from './models';
import { fetchDashboard } from './api';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';

interface DashboardState extends Dashboard {
  isLoaded: boolean;
}

const initialState: DashboardState = {
  isLoaded: false,
  user: {
    registed: [],
    total: 0,
  },
  session: {
    count: [],
  },
};

export const fetchDashboardThunk = createAppAsyncThunk<Dashboard>(
  'admin/dashboard/fetchDashboard',
  async () => {
    return (await fetchDashboard()).data;
  },
  {
    condition: (_, { getState }) => {
      const { isLoaded } = getState().admin.dashboard;
      return !isLoaded;
    },
  }
);

const slice = createSlice({
  name: 'admin/dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardThunk.fulfilled, (state, action) => {
      state.isLoaded = true;
      state.user = action.payload.user;
      state.session = action.payload.session;
    });
  },
});

export default slice.reducer;
