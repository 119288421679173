import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './redux/store';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppHistory = useHistory;

export const useDebouncedEffect = (effect: () => void, delay: number, deps: any[]) => {
  const callback = useRef(effect);

  // 更新effect的引用
  useEffect(() => {
    callback.current = effect;
  }, [effect]);

  // 执行延迟的effect
  useEffect(() => {
    const handler = () => {
      callback.current();
    };

    const id = setTimeout(handler, delay);
    return () => clearTimeout(id);
  }, [delay, ...deps]);
};
