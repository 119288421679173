import React, { useCallback, useEffect } from 'react';
import UserAvatar from 'user/components/UserAvatart';
import BotAvatar from 'bot/components/BotAvatar';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './index.scss';
import classNames from 'classnames';
import throttle from 'app/utils/throttle';
import { Bot, BotSessionMessageContent, ContentRole } from 'bot/models';
import { useAppSelector } from 'app/hooks';
import { findLastHTMLElement } from 'app/utils';

interface BotMessageListProps {
  messages: BotSessionMessageContent[];
  reply: string;
  typing: boolean;
}

const UserMessage: React.FC<{ content: string }> = ({ content }) => {
  return (
    <div className={classNames('bot-message', ContentRole.USER)}>
      <div className="content-section">
        <div className="bot-message-avatar">
          <UserAvatar />
        </div>
        <div className="bot-message-content">
          <pre className="user-content">{content}</pre>
        </div>
      </div>
    </div>
  );
};

const BotMessage: React.FC<{ bot: Bot; content: string; typing?: boolean }> = ({
  bot,
  content,
  typing,
}) => {
  const markdownRef = React.useRef<HTMLDivElement>(null);
  const cursorRef = React.useRef<HTMLSpanElement>(null);
  const throttleAppendingCursor = useCallback(
    throttle(() => {
      if (markdownRef.current && cursorRef.current) {
        // 添加光标到.bot-message-content下面的最后一个子元素中
        let lastElement = markdownRef.current.querySelector(
          '.bot-message-content > *:last-child'
        );
        if (lastElement) {
          if (lastElement.tagName === 'OL') {
            lastElement =  findLastHTMLElement(lastElement as HTMLElement);
          } else {
            lastElement = findLastHTMLElement(lastElement as HTMLElement);
          }
        } else {
          lastElement = markdownRef.current.querySelector('.bot-message-content');
        }

        lastElement?.insertAdjacentElement('beforeend', cursorRef.current!);
      }
    }, 1050),
    []
  );
  useEffect(() => {
    throttleAppendingCursor();
  }, [content, typing]);
  return (
    <div className={classNames('bot-message', ContentRole.ASSISTANT)}>
      <div className="content-section" ref={markdownRef}>
        <div className="bot-message-avatar">
          <BotAvatar bot={bot} />
        </div>
        <ReactMarkdown
          className="bot-message-content"
          children={content}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  style={vscDarkPlus}
                  PreTag="div"
                  className={classNames(className, 'block-code')}
                />
              ) : (
                <code {...props} className={classNames(className, 'inline-code')}>
                  {children}
                </code>
              );
            },
          }}
        />
        {typing && <span ref={cursorRef} className="cursor" />}
      </div>
    </div>
  );
};

const BotMessageList: React.FC<BotMessageListProps> = ({ messages, typing, reply }) => {
  const bot = useAppSelector((state) => state.bot.workdesk.bot);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      // 判断messageEndRef.current是否在可视区域内
      const rect = messagesEndRef.current.getBoundingClientRect();
      if (rect.top < 0 || (rect.bottom > window.innerHeight - 100)) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // 使用节流函数限制滚动频率
  const throttledScrollToBottom = useCallback(throttle(scrollToBottom, 1000), []);

  useEffect(() => {
    if (messages.length > 0) {
      throttledScrollToBottom();
    }
  }, [messages]);

  return (
    <div className="bot-message-list">
      {messages.map((message, index) => {
        if (message.role === ContentRole.USER) {
          return <UserMessage key={index} content={message.content} />;
        } else {
          return <BotMessage key={index} bot={bot!} content={message.content} />;
        }
      })}
      {typing && <BotMessage bot={bot!} content={reply} typing />}
      <div ref={messagesEndRef} className="message-end" />
    </div>
  );
};

export default BotMessageList;
