export function isMobile() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let flag = false;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = true;
        break;
      }
    }
    if(window.screen.width<500){
      flag = true;
    }
    return flag;
}

export function isWechat() {
  const userAgent = navigator.userAgent;
  return /micromessenger/.test(userAgent.toLowerCase());
}