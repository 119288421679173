/**
 * 截取文本
 * @param text
 * @param length
 * @returns
 */
export const cutText = (text: string, length: number = 100) => {
  if (!text) {
    return '';
  }

  if (text.length > length) {
    return text.slice(0, length) + '...';
  }
  return text;
};

export function removeQueryParameters(keys: string[]) {
  // 获取当前URL
  const url = new URL(window.location.href);

  // 移除特定的查询参数
  keys.forEach((key) => url.searchParams.delete(key));

  // window.location.href = url.toString();
  window.location.replace(url.toString());
}

// 把名字转成只显示第一个字的svg
export const createTitleSvg = (name: string) => {
  const firstLetter = name[0];
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <rect width="100%" height="100%" fill="#007bff" />
      <text x="50%" y="50%" dy=".37em" textAnchor="middle" fill="#fff" fontSize="40">
        {firstLetter}
      </text>
    </svg>
  );
};

// 递归找到DOM下的最后一个HTMLElement, 可以指定tag
export const findLastHTMLElement = (element: HTMLElement): HTMLElement => {
  // 如果该DOM没有子元素，则返回自身
  if (!element.children.length) {
    // 如果element是inline code，则返回父元素
    if (element.tagName === 'CODE' && element.className === 'inline-code') {
      return element.parentElement as HTMLElement;
    }
    return element;
  }

  // 倒序查找子元素，返回第一个非span class='cursor'的元素
  let lastChild: HTMLElement | null = null;
  for (let i = element.children.length - 1; i >= 0; i--) {
    lastChild = element.children[i] as HTMLElement;
    if (lastChild.tagName !== 'SPAN' || lastChild.className !== 'cursor') {
      break;
    } else {
      lastChild = null;
    }
  }

  if (!lastChild) {
    return element;
  }

  // 如果最后一个子元素是元素节点，则递归查找
  if (lastChild.nodeType === Node.ELEMENT_NODE && !['P'].includes(lastChild.tagName)) {
    return findLastHTMLElement(lastChild as HTMLElement);
  }
  return element;
};
