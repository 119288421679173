import { ProFormInstance } from '@ant-design/pro-components';
import { createBot, updateBot } from 'admin/bots/api';
import { Bot, BotStatus } from 'admin/bots/models';
import { fetchBotFormConfigThunk } from 'admin/bots/slice';
import { useAppDispatch, useAppHistory, useAppSelector } from 'app/hooks';
import { flatObjectToQueryString } from 'app/services/api';
import { useCallback, useEffect, useRef } from 'react';

export type BotFormMode = 'CREATE' | 'UPDATE';

const INITIAL_VALUES = {
  type: 'micro_app',
  status: BotStatus.OFFLINE,
};

export const useBotForm = (mode: BotFormMode, bot?: Bot) => {
  const history = useAppHistory();
  const formRef = useRef<ProFormInstance<Bot>>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchBotFormConfigThunk());
  }, [dispatch]);

  const formConfig = useAppSelector((state) => state.admin.bot.formConfig);

  let initialValues: Partial<Bot> = {};
  const finishCallback = useCallback(
    async (formData: Bot) => {
      if (mode === 'CREATE') {
        const result = await createBot(formData);
        if (result.isOK) {
          history.push('/admin/bots/list');
        }
      }
      if (mode === 'UPDATE' && bot) {
        const result = await updateBot(bot.id, formData);
        if (result.isOK) {
          history.push('/admin/bots/list');
        }
      }
    },
    [mode, bot]
  );

  const setAvatarUrl = useCallback(
    (url: string) => {
      formRef.current?.setFieldsValue({ avatar_url: url });
    },
    [formRef]
  );

  if (mode === 'CREATE') {
    initialValues = INITIAL_VALUES;
  } else if (bot) {
    initialValues = bot;
  }

  return {
    formRef,
    formConfig,
    initialValues,
    finishCallback,
    setAvatarUrl,
  };
};

export const useChatURL = ({ botId, sessionId }: { botId?: string; sessionId?: string }) => {
  const { isLogin } = useAppSelector((state) => state.user.auth);
  if (!botId && !sessionId) {
    return '';
  }
  // 如果已经登录，则跳转到workdesk,否则，去playground
  let chatURL = isLogin ? '/bot/workdesk' : '/bot/playground';

  chatURL += '?' + flatObjectToQueryString({ botId, sessionId });

  return chatURL;
};
