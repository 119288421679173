import callApi, { DataWithPagination, PaginationParams } from 'app/services/api';
import { Bot, BotFormConfig } from './models';
import { RcFile } from 'antd/es/upload';

export const fetchBotList = async (params: PaginationParams) => {
  return callApi<DataWithPagination<Bot>>({
    endpoint: '/admin/bots',
    method: 'GET',
    data: params,
  });
};

export const fetchBotFormConfig = async () => {
  return callApi<BotFormConfig>({
    endpoint: '/admin/bots/form_config',
    method: 'GET',
  });
};

export const createBot = async (data: Bot) => {
  return callApi<Bot>({
    endpoint: '/admin/bots/create',
    method: 'POST',
    data,
  });
};

export const updateBot = async (id: Bot['id'], data: Bot) => {
  return callApi<Bot>({
    endpoint: `/admin/bots/${id}`,
    method: 'PUT',
    data,
  });
};

export const deleteBot = async (id: Bot['id']) => {
  return callApi<Bot>({
    endpoint: `/admin/bots/${id}`,
    method: 'DELETE',
  });
};

export const fetchBot = async (id: Bot['id']) => {
  return callApi<Bot>({
    endpoint: `/admin/bots/${id}`,
    method: 'GET',
  });
};

// 上传助手头像
export const uploadAvatar = async (file: RcFile) => {
  const formData = new FormData();
  formData.append('file', file);
  return callApi<{url: string}>({
    endpoint: '/admin/bots/upload_avatar',
    method: 'POST',
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};