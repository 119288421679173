import React, { useEffect } from 'react';

import BaseLayout from 'app/layouts/BaseLayout';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import SessionRecordPane from './SessionRecordPane';

import './index.scss';
import BotRecordPane, { BotRecordPaneKey } from './BotRecordPane';
import { fetchUserDashboardThunk } from 'user/slice';

const makeGreeting = () => {
  // 根据当前时间生成问候语，早上好，中午好，下午好，晚上好
  const now = new Date();
  const hour = now.getHours();
  let greeting = '早上好';
  if (hour >= 12 && hour < 14) {
    greeting = '中午好';
  } else if (hour >= 14 && hour < 18) {
    greeting = '下午好';
  } else if (hour >= 18 && hour < 24) {
    greeting = '晚上好';
  }
  return greeting;
};

const UserDashboard = () => {
  const userState = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const isLogin  = userState.auth.isLogin;

  useEffect(() => {
    if (isLogin) {
      dispatch(fetchUserDashboardThunk());
    }
  }, [isLogin]);

  return (
    <BaseLayout className="user-dashboard" pageName="个人中心">
      <div className="greeting">
        {makeGreeting()}, {userState.profile.nickname}, 这是你的个人中心
      </div>
      <SessionRecordPane title="最近对话" sessions={userState.dashboard.latest_sessions} />
      <BotRecordPane
        type={BotRecordPaneKey.LATEST}
        title="最近使用的助手"
        bots={userState.dashboard.latest_bots}
      />
      <BotRecordPane
        type={BotRecordPaneKey.MOST_USED}
        title="最常使用的助手"
        bots={userState.dashboard.most_used_bots}
      />
      {/* <BotRecordPane
        type={BotRecordPaneKey.FAVORATE}
        title="收藏的助手"
        bots={userState.dashboard.favorite_bots}
      /> */}
      {/* <BotRecordPane title="创建的助手" bots={userState.dashboard.created_bots} /> */}
    </BaseLayout>
  );
};

export default UserDashboard;
