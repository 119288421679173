import React from 'react';

import './index.scss';
import BaseLayout from 'app/layouts/BaseLayout';
import { Button, Col, Grid, QRCode, Row } from 'antd';
import { WechatOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

const Contact = () => {
  const screens = useBreakpoint();
  return (
    <BaseLayout className="contact-us" pageName="联系我们">
      <Col span={18} offset={3}>
        <h1>如果在使用中遇到任何问题或者希望我们支持更多场景的AI助手，请联系我们</h1>
        <div className="contact-methods">
          <h2>联系方式</h2>
          <div className="contact-method">
            <div className="content">
              {screens.xs && (
                <Button
                  type="primary"
                  size='large'
                  icon={<WechatOutlined />}
                  onClick={() => {
                    window.open('https://work.weixin.qq.com/kfid/kfcc9470ec1b2f1c89a', '_blank');
                  }}
                >
                  微信客服
                </Button>
              )}
              {/* {!screens.xs && <img src="/images/contact-wechat.png" alt="wechat-qrcode" />} */}
              {!screens.xs && (
                <QRCode
                  value="https://work.weixin.qq.com/kfid/kfcc9470ec1b2f1c89a"
                  size={200}
                  icon="/images/logo.png"
                  className="wechat-qrcode"
                />
              )}
            </div>
          </div>
        </div>
      </Col>
    </BaseLayout>
  );
};

export default Contact;
