import { UserAuth } from './models';

const STORAGE_KEY = 'auth_token';

export const remember = (token: UserAuth['token']) => {
  if (!token) {
    return;
  }
  localStorage.setItem(STORAGE_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export const isRemember = () => {
  return !!localStorage.getItem(STORAGE_KEY);
};

export function getAuthToken(): UserAuth['token'] | null {
  const token = localStorage.getItem(STORAGE_KEY);
  return token;
}
