import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';
import { deleteSession, fetchBotDetail, fetchDefaultBot, fetchSessionDetail, fetchSessionList, generateSessionTitle } from 'bot/api';
import { Bot, BotSession, ContentRole } from 'bot/models';

interface WorkdeskState {
  bot: Bot | null; // 当前助手
  sessions: BotSession[] | null; // 当前助手会话列表, Sider中使用
  session: BotSession | null; // 当前会话
}

const initialState: WorkdeskState = {
  bot: null,
  sessions: null,
  session: null,
};

/**
 * 获取助手详情
 */
export const fetchBotDetailThunk = createAppAsyncThunk<Bot, string>(
  'bot/fetchBotDetail',
  async (botID) => {
    return (await fetchBotDetail(botID)).data;
  }
);

/**
 * 获取会话详情
 */
export const fetchSessionDetailThunk = createAppAsyncThunk<BotSession, string>(
  'bot/fetchSessionDetail',
  async (sessionID) => {
    return (await fetchSessionDetail(sessionID)).data;
  },
  {
    condition: (sessionID, { getState }) => {
      // 需要登录才发送
      const isLogin = getState().user.auth.isLogin;
      return isLogin;
    },
  }
);

/**
 * 获取助手会话列表
 */
export const fetchBotSessionsThunk = createAppAsyncThunk<BotSession[], string>(
  'bot/fetchBotSessions',
  async (botID) => {
    return (await fetchSessionList(botID)).data;
  },
  {
    condition: (sessionID, { getState }) => {
      // 需要登录才发送
      const isLogin = getState().user.auth.isLogin;
      return isLogin;
    },
  }
);

/**
 * 获取默认助手
 */
export const fetchDefaultBotThunk = createAppAsyncThunk<Bot>('bot/fetchDefaultBot', async () => {
  return (await fetchDefaultBot()).data;
});

/**
 * 删除会话
 */
export const deleteSessionThunk = createAppAsyncThunk<{ session_id: string }, string>(
  'bot/deleteSession',
  async (sessionID) => {
    return (await deleteSession(sessionID)).data;
  }
);

/**
 * 生成新会话标题
 */
export const generateSessionTitleThunk = createAppAsyncThunk<Pick<BotSession, 'id' | 'title'>, string>(
  'bot/generateSessionTitle',
  async (sessionID) => {
    return (await generateSessionTitle(sessionID)).data;
  }
);


const slice = createSlice({
  name: 'bot/workdesk',
  initialState,
  reducers: {
    setSession(state, action) {
      // 设置当前会话，新会话有了第一条返回之后设置
      state.session = action.payload;
    },
    addMessage(state, action) {
      // 给当前会话添加消息
      if (state.session) {
        state.session.messages.push(action.payload);
      } else {
        // 暂时强制插入第一条
        state.session = {
          messages: [action.payload],
        } as BotSession;
      }
    },
    removeLastUserMessage(state) {
      if (state.session) {
        // 如果最后一条消息是用户消息，删除
        const lastMessage = state.session.messages[state.session.messages.length - 1];
        if (lastMessage && lastMessage.content.role === ContentRole.USER) {
          state.session.messages.pop();
          return state;
        }
      }
    },
    newSession(state) {
      state.session = null;
    },
    insertNewSession(state, action) {
      // 把新的会话插入到sessions的第一条
      if (state.sessions) {
        state.sessions.unshift({
          ...action.payload,
          updated_at: new Date().toISOString(),
          isNew: true,
        });
      }
    },
    clearWorkdesk(state) {
      state.bot = null;
      state.session = null;
      state.sessions = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBotDetailThunk.fulfilled, (state, action) => {
      state.bot = action.payload;
    });
    builder.addCase(fetchSessionDetailThunk.fulfilled, (state, action) => {
      state.session = action.payload;
      state.bot = action.payload.bot;
    });
    builder.addCase(fetchBotSessionsThunk.fulfilled, (state, action) => {
      state.sessions = action.payload;
    });
    builder.addCase(fetchDefaultBotThunk.fulfilled, (state, action) => {
      state.bot = action.payload;
    });
    builder.addCase(deleteSessionThunk.fulfilled, (state, action) => {
      state.sessions =
        state.sessions?.filter((session) => session.id !== action.payload.session_id) ?? null;
    });
    builder.addCase(generateSessionTitleThunk.fulfilled, (state, action) => {
      // 更新sessions中对应的session的title
      if (state.sessions) {
        const session = state.sessions.find((session) => session.id === action.payload.id);
        if (session) {
          session.title = action.payload.title;
        }
      }
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;
