import { List } from 'antd';
import Card from 'antd/es/card/Card';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { UserSession } from 'user/models';

interface UserSessionPaneProps {
  title: string;
  sessions: UserSession[];
}

const SessionRecordPane: FunctionComponent<UserSessionPaneProps> = ({ title, sessions }) => {
  return (
    <Card title={title} className="session-record-pane">
      {sessions.length === 0 && (
        <div>
          还没有记录，去<Link to="/bot/center">助手中心</Link>寻找你需要的助手，或者开始与ChatGPT的
          <Link to="/bot/playground">快速体验</Link>吧!
        </div>
      )}
      {sessions.length > 0 && (
        <div className="sessions-container">
          {sessions.map((session) => (
            <div className="session-card" key={session.session.id}>
              <Link to={`/bot/workdesk?sessionId=${session.session.id}`}>
                <div className="title">{session.session.title}</div>
                <div className="updated-at">{session.updated_at}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default SessionRecordPane;
