import { useEffect } from 'react';
import { Bot } from './models';
import { configWechatShare } from 'app/utils/wechat-share';
import wx from 'weixin-js-sdk';

export const useShareWorkdeskToWechat = (bot: Bot | null) => {
  const url = window.location.href.split('#')[0];

  useEffect(() => {
    const initWechatConfig = async () => {
      if (!bot) {
        return;
      }
      await configWechatShare(url);
      const title = bot?.name;
      const desc = bot?.description;
      const imgUrl = bot?.avatar || 'https://chatwithjarvis.com/images/logo.png';

      // 设置分享到朋友圈
      wx.ready(() => {
        wx.updateTimelineShareData({
          title,
          link: url,
          imgUrl,
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });

        // 设置分享给朋友
        wx.updateAppMessageShareData({
          title,
          desc,
          link: url,
          imgUrl,
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
      });
    };

    // 如果url中包含code参数，说明是从微信跳转过来的，正在登录，不需要初始化微信配置
    if (bot && !url.includes('wechat_login')) {
      initWechatConfig();
    }
  }, [bot, url]);
};