import { UserProfile } from './models';
import { UserAuth } from 'user/models';
import callApi from 'app/services/api';

export interface LoginParams {
  username: string;
  password: string;
}

export interface LoginResult {
  token: UserAuth['token'];
  nickname: UserProfile['nickname'];
  avatar: UserProfile['avatar'];
  is_admin: boolean;
}

export const login = async (loginParams: LoginParams) => {
  return callApi<LoginResult>({
    endpoint: '/auth/login',
    data: {
      admin: true,
      ...loginParams,
    },
    method: 'POST',
  });
};

export const fetchCurrentUser = async () => {
  return callApi<LoginResult>({
    endpoint: '/auth/current_user?admin=1',
    method: 'GET',
  });
};
