import React from 'react';
import { useLogout } from 'user/hooks';

const Logout = () => {
  const handler = useLogout();

  return (
    <div className="logout" onClick={handler}>
      退出登录
    </div>
  );
};

export default Logout;
