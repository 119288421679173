import React, { useEffect } from 'react';
import ChatPane from 'bot/components/ChatPane';
import WorkdeskSider from 'bot/components/WorkdeskSider';

import './index.scss';
import { useInitWorkdesk } from './hooks';
import { useAppSelector } from 'app/hooks';
import { useShareWorkdeskToWechat } from 'bot/hooks';
import Header from 'app/components/Header';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const Workdesk: React.FC<{ hasDefault: boolean }> = ({ hasDefault }) => {
  const { bot, session } = useAppSelector((state) => state.bot.workdesk);
  const { isLogin } = useAppSelector((state) => state.user.auth);
  // 查看url中是否有default参数
  const fromMenu = new URLSearchParams(window.location.search).get('fromMenu');

  useInitWorkdesk(hasDefault || !!fromMenu);

  useEffect(() => {
    if (session && session.title) {
      document.title = session.title;
    } else {
      document.title = '新会话';
    }
  }, [session]);

  useShareWorkdeskToWechat(bot);

  const screens = useBreakpoint();

  return (
    <div className="bot-workdesk">
      {screens.xs && isLogin && <Header />}
      <WorkdeskSider bot={bot} session={session} />
      <section className="bot-workdesk__main">
        <ChatPane bot={bot} session={session} />
      </section>
    </div>
  );
};

export default Workdesk;
