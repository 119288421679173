import { useAppDispatch, useAppHistory, useAppSelector } from 'app/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  actions,
  deleteSessionThunk,
  fetchBotDetailThunk,
  fetchBotSessionsThunk,
  fetchDefaultBotThunk,
  fetchSessionDetailThunk,
} from './slice';
import {
  Bot,
  BotSession,
  BotSessionMessage,
  BotSessionMessageContent,
  ContentRole,
} from 'bot/models';
import { BASE_URL } from 'app/services/api';
import { EventData } from 'bot/components/ChatPane/types';
import { ChatParams, ChatResponse, fetchChatReply } from 'bot/api';
import createPostSSE, { SSEConnection } from 'app/utils/post-sse';

/**
 * 初始化工作台
 */
export const useInitWorkdesk = (hasDefault: boolean = false) => {
  const dispatch = useAppDispatch();

  const botID = new URLSearchParams(window.location.search).get('botId');
  const sessionID = new URLSearchParams(window.location.search).get('sessionId');

  const curSessionID = useAppSelector((state) => state.bot.workdesk.session?.id);
  const curBotID = useAppSelector((state) => state.bot.workdesk.bot?.id);
  const isLogin = useAppSelector((state) => state.user.auth.isLogin);

  useEffect(() => {
    const init = () => {
      // 如果没有bot_id也没有sessionID那么去获取默认bot
      if (!botID && !sessionID && hasDefault) {
        dispatch(fetchDefaultBotThunk()).then((action) => {
          if (action.payload) {
            dispatch(fetchBotSessionsThunk((action.payload as Bot).id));
          }
        });
      }

      // 如果有botID, 且不等于当前botID，那么去获取botDetail
      if (botID) {
        dispatch(fetchBotDetailThunk(botID)).then((action) => {
          if (action.payload) {
            dispatch(fetchBotSessionsThunk((action.payload as Bot).id));
          }
        });
      }

      // 如果有sessionID，那么去获取sessionDetail
      if (sessionID) {
        dispatch(fetchSessionDetailThunk(sessionID)).then((action) => {
          if (action.payload) {
            dispatch(fetchBotSessionsThunk((action.payload as BotSession).bot.id));
          }
        });
      }
    };

    if (curSessionID && sessionID && curSessionID === sessionID) {
      // 如果处理的是同一个会话，那么不需要重新初始化
      return;
    } else if (botID && curBotID && botID === curBotID) {
      // 如果处理的是同一个bot，那么不需要重新初始化（新会话的跳转）
      return;
    } else if (isLogin) {
      init();
    }

    // 先获取默认bot
    if (!botID && !sessionID && hasDefault && !curBotID) {
      dispatch(fetchDefaultBotThunk());
    }
  }, [botID, sessionID, hasDefault, curSessionID, curBotID, isLogin]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearWorkdesk());
    }
  }, []);
};

/**
 * 使用ChatPane
 */
export const useChatPane = (bot: Bot | null, session: BotSession | null) => {
  const STREAM = true;
  const dispatch = useAppDispatch();
  const history = useAppHistory();

  // 是否正在加载
  const [loading, setLoading] = useState<boolean>(false);
  // 当前回复
  const [reply, setReply] = useState<string>('');
  // 当前回复的内容
  const replyRef = useRef<string>('');
  // SSE连接
  const sseConnection = useRef<SSEConnection | null>(null);
  // 当前输入
  const [input, setInput] = useState<string>('');

  const userAuth = useAppSelector((state) => state.user.auth);

  const messages: BotSessionMessageContent[] = session
    ? session.messages.map((m) => m.content)
    : [];

  const addMessage = useCallback((message: BotSessionMessage) => {
    dispatch(actions.addMessage(message));
  }, []);

  /**
   * 设置当前会话
   * 如果当前对话是新的(session为空), 则在第一条消息返回之后设置
   * 根据后端回传的session_id, 拉取session详情
   */
  const setNewSession = useCallback(
    (session_id: BotSession['id']) => {
      if (session) {
        return;
      }
      // 如果session为空，那么去获取session详情
      dispatch(fetchSessionDetailThunk(session_id)).then((action) => {
        dispatch(actions.setSession(action.payload));
        // 同时插入sessions的第一条
        dispatch(actions.insertNewSession(action.payload as BotSession));
        // 把url中的botId去掉，换成sessionId, 但不刷新页面
        history.replace({
          search: `?sessionId=${session_id}`,
        });
      });
    },
    [session]
  );

  /**
   * 终止回复
   * 当用户发现回答不符合预期时，可以终止回复
   * 通常这种情况下，用户会修改输入
   * 1. 关闭SSEConnection
   * 2. 清空reply
   * 3. 清空replyRef
   * 4. 设置上一个USER的message的content为query，作为用户的默认输入
   * 5. 设置loading为false
   */
  const stopGeneration = () => {
    if (sseConnection.current) {
      sseConnection.current.close();
    }
    setReply('');
    replyRef.current = '';
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === ContentRole.USER) {
        setInput(lastMessage.content);
      }
    }
    // 移除最后一个用户消息
    dispatch(actions.removeLastUserMessage());
    setLoading(false);
  };

  if (!bot) {
    return;
  }

  // 更新 reply 时，同时更新 replyRef
  const handleReply = (data: string) => {
    setReply((pre) => pre + data);
    replyRef.current = replyRef.current + data;
  };

  const handleSubmit = async () => {
    setLoading(true);
    addMessage({ content: { role: ContentRole.USER, content: input }, type: 'TEXT' });
    replyRef.current = '';
    setInput('');

    // 如果STREAM为true，那么就使用EventSource，否则使用getChatReply
    if (STREAM) {
      const data = {
        content: input,
        auth_token: userAuth.token,
        session_id: session?.id,
      };
      sseConnection.current = createPostSSE({
        url: `${BASE_URL}/bot/${bot.id}/chat-stream`,
        data,
        onNewData: (eventData: string) => {
          const data: EventData = JSON.parse(eventData);
          if (data.type === 'session_id' && !session) {
            setNewSession(data.content);
          } else if (data.type === 'reply') {
            if (data.content) {
              handleReply(data.content);
            }
          }
        },
        onConnectionClosed: () => {
          setLoading(false);
          setReply('');
          addMessage({
            content: { role: ContentRole.ASSISTANT, content: replyRef.current },
            type: 'TEXT',
          });
        }
      });
    } else {
      const params: ChatParams = {
        bot_id: bot.id,
        content: input,
        stream: false,
      };
      if (session) {
        params.session_id = session.id;
      }
      const result: ChatResponse = (await fetchChatReply(params)).data;
      setNewSession(result.session_id);
      addMessage({
        content: { role: ContentRole.ASSISTANT, content: result.reply },
        type: 'TEXT',
      });
      setLoading(false);
    }
  };

  return {
    state: {
      loading,
      reply,
      messages,
      input,
    },
    handlers: {
      handleSubmit,
      handleReply,
      stopGeneration,
      setInput,
    },
  };
};

export const useSider = () => {
  const { bot, sessions, session } = useAppSelector((state) => state.bot.workdesk);
  const dispatch = useAppDispatch();
  const history = useAppHistory();
  const newSession = useCallback(() => {
    if (bot) {
      dispatch(actions.newSession());
      history.push(`/bot/workdesk?botId=${bot.id}`);
    }
  }, [bot]);

  const deleteSession = useCallback(
    (session: BotSession, isActive: boolean) => {
      dispatch(deleteSessionThunk(session.id)).then((action) => {
        // 如果删除的是当前会话，则跳转到botId对应的Workdesk
        if (isActive && bot) {
          newSession();
        }
      });
    },
    [bot]
  );

  return {
    state: {
      session,
      sessions,
    },
    handlers: {
      newSession,
      deleteSession,
    },
  };
};
