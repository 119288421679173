import React, { FunctionComponent } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './index.scss';
import { useHistory } from 'react-router-dom';

const Banner: FunctionComponent<{}> = () => {
  const history = useHistory();

  return (
    <div className="banner">
      <QueueAnim
        key="QueueAnim"
        type={['bottom', 'top']}
        delay={200}
        className="banner-text-wrapper"
      >
        <div key="title" className="banner-title">
          JarvisAI
        </div>
        <div key="content" className="banner-content">
          一站式AI助手平台，轻松赋能工作与生活
        </div>
        <Button
          ghost
          key="button"
          className="banner-button"
          onClick={() => {
            history.push('/bot/playground');
          }}
        >
          快速体验
        </Button>
      </QueueAnim>
      <React.Fragment>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </React.Fragment>
    </div>
  );
};
export default Banner;
