import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import BaseLayout from 'app/layouts/BaseLayout';
import BotCard from 'bot/components/BotCard';
import { fetchBotListThunk } from './slice';

import './index.scss';

const BotCenter = () => {
  const botList = useAppSelector((state) => state.bot.list);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchBotListThunk());
  }, []);

  return (
    <BaseLayout className="bot-market" pageName="助手中心">
      <div className="bot-list">
        {botList.map((bot) => {
          return <BotCard key={bot.id} bot={bot} />;
        })}
      </div>
    </BaseLayout>
  );
};

export default BotCenter;
