import React, { useCallback, useEffect, useRef } from 'react';

import './index.scss';
import LoginBtn from 'user/components/LoginBtn';
import { useAppSelector } from 'app/hooks';
import Avatar from 'antd/es/avatar/avatar';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import Logout from '../Logout';


const items = [
  {
    key: 'user-dashboard',
    label: <Link to="/user/dashboard">个人中心</Link>,
  },
  {
    key: 'user-workdesk',
    label: <Link to="/bot/workdesk?fromMenu=1">工作台</Link>,
  },
  {
    key: 'logout',
    label: <Logout />,
  },
];
/**
 * 用户信息，用于Header
 * 如果用户已经登录，则显示用户头像以及昵称
 * 如果用户未登录，则显示登录按钮
 */
const AuthUserInfo = () => {
  const userState = useAppSelector((state) => state.user);
  const history = useHistory();
  const preAuth = useRef<boolean>(userState.auth.isLogin);

  const onClickUserInfo = useCallback(() => {
    history.push('/user/dashboard');
  }, []);

  // 监听登录状态的变化，如果登录成功，则跳转到/user/dashboard, 如果登出成功，则跳转到/
  useEffect(() => {
    // 只有状态发生变化时才执行
    if (preAuth.current === userState.auth.isLogin) {
      return;
    }

    const currentPath = window.location.pathname;
    if (userState.auth.isLogin && currentPath === '/') {
      history.push('/user/dashboard');
    }
    if (!userState.auth.isLogin) {
      history.push('/');
    }
    preAuth.current = userState.auth.isLogin;
  }, [userState.auth.isLogin]);

  // 如果当前path是/user/dashboard，则给className添加active
  const pathname = window.location.pathname;

  return (
    <div
      className={classNames(
        'auth-user-info',
        ['/user/dashboard', '/bot/workdesk'].includes(pathname) ? 'active' : null
      )}
    >
      {userState.auth.isLogin && (
        <Dropdown menu={{ items }}>
          <div className="user-info" onClick={onClickUserInfo}>
            <Avatar src={userState.profile.avatar} />
            <span className="username">{userState.profile.nickname}</span>
          </div>
        </Dropdown>
      )}
      {!userState.auth.isLogin && <LoginBtn />}
    </div>
  );
};

export default AuthUserInfo;
