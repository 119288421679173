export enum BotStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  PENDING = 'PENDING',
}

export interface Bot {
  id: string;
  name: string;
  description: string;
  status: BotStatus;
  created_at: string;
  scope: string;
  config: string;
  avatar_url: string;
  avatar: string;
  guide: string; // 引导语，在开启会话时，先显示引导语
  prompt: string; // 助手设定
  type: string; // 机器人类型
}

type ConfigOption = {
  label: string;
  value: string;
};

export interface BotFormConfig {
  status: ConfigOption[];
  scope: ConfigOption[];
  type: ConfigOption[];
}
