import callApi from 'app/services/api';
import { Bot, BotSession } from './models';

export interface ChatParams {
  bot_id: string;
  content: string;
  session_id?: string;
  stream: boolean;
}

export interface ChatResponse {
  session_id: string;
  reply: string;
}

export interface ChatStreamResponse {
  type: string;
  content: string;
}

// 获取机器人返回的消息，返回类型可能是ChatResponse或ChatStreamResponse
export const fetchChatReply = async (params: ChatParams) => {
  const { bot_id, ...rest } = params;

  return callApi<ChatResponse>({
    endpoint: `/bot/${bot_id}/chat`,
    method: 'POST',
    data: rest,
  });
};

// 返回助手列表
export const fetchBotList = async () => {
  return callApi<Bot[]>({
    endpoint: '/bot/list',
  });
};

// 返回默认助手
export const fetchDefaultBot = async () => {
  return callApi<Bot>({
    endpoint: '/bot/default',
  });
};

// 返回bot详情
export const fetchBotDetail = async (bot_id: string) => {
  return callApi<Bot>({
    endpoint: `/bot/${bot_id}`,
  });
};

// 返回Session信息
export const fetchSessionDetail = async (session_id: string) => {
  return callApi<BotSession>({
    endpoint: `/bot/sessions/${session_id}`,
  });
};

// 获取助手会话记录
export const fetchSessionList = async (bot_id: string) => {
  return callApi<BotSession[]>({
    endpoint: `/bot/${bot_id}/sessions`,
  });
};

// 删除会话(软删除)
export const deleteSession = async (session_id: string) => {
  return callApi<{session_id: string}>({
    endpoint: `/bot/sessions/${session_id}`,
    method: 'DELETE',
  });
}

// 生成新会话的标题
export const generateSessionTitle = async (session_id: string) => {
  return callApi<Pick<BotSession, 'id' | 'title'>>({
    endpoint: `/bot/sessions/${session_id}/generate_title`,
    method: 'GET',
  });
}