import React, { FunctionComponent, useEffect, useState } from 'react';
import { Header as AntHeader } from 'antd/es/layout/layout';

import './index.scss';
import AuthUserInfo from 'user/components/AuthUserInfo';
import { Col, Menu, Row, Grid, Button, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';

const { useBreakpoint } = Grid;

const MENU_LIST = [
  {
    key: 'welcome',
    text: '首页',
    path: '/',
  },
  {
    key: 'bot_center',
    text: '助手中心',
    path: '/bot/center',
  },
  {
    key: 'playground',
    text: '快速体验',
    path: '/bot/playground',
  },
  {
    key: 'contact',
    text: '联系我们',
    path: '/contact',
  }
];

interface PCHeaderProps {
  selectedKey?: string;
  setSelectedKey: (key: string) => void;
}

const PCHeader: FunctionComponent<PCHeaderProps> = ({ selectedKey, setSelectedKey }) => {
  return (
    <AntHeader
      className="jarvis-header"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Row justify="center" align="middle" style={{ width: '100%' }}>
        <Col xs={24} sm={22} md={20} lg={18} xl={16} className="content-wrapper">
          <Link to="/" className="logo-wrapper">
            <img src="/images/logo.png" alt="logo" />
            <span className="logo-text">JarvisAI</span>
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={selectedKey ? [selectedKey] : undefined}
            className="menu"
          >
            {MENU_LIST.map((item) => (
              <Menu.Item key={item.key} onClick={() => setSelectedKey(item.key)}>
                <Link to={item.path}>{item.text}</Link>
              </Menu.Item>
            ))}
          </Menu>
          <AuthUserInfo />
        </Col>
      </Row>
    </AntHeader>
  );
};

const Header: FunctionComponent<{}> = () => {
  const [selectedKey, setSelectedKey] = useState<string>();
  const pathname = window.location.pathname;
  const screens = useBreakpoint();
  const [visible, setVisible] = useState(false);
  const [delta, setDelta] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const key = MENU_LIST.find((item) => pathname === item.path)?.key;
    if (key) {
      setSelectedKey(key);
    }
  }, [pathname]);

  return (
    <>
      {screens.xs ? (
        <div className="mobile-header-container">
          <Draggable
            onStart={(_, data) => setDelta({
              x: data.x,
              y: data.y,
            })}
            onStop={(_, data) => {
              // 只要位移的绝对值没有超过5个像素，就认为是点击事件
              if (Math.abs(data.x - delta.x) < 5 && Math.abs(data.y - delta.y) < 5) {
                showDrawer();
              }
            }}
          >
            <div className="trigger-btn-container">
              <Button
                type="primary"
                className="trigger-btn"
                onClick={showDrawer}
                icon={<MenuOutlined />}
              />
            </div>
          </Draggable>
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            open={visible}
            className="mobile-header"
            width={140}
          >
            <Link to="/" className="logo-wrapper">
              <img src="/images/logo.png" alt="logo" />
              <span className="logo-text">JarvisAI</span>
            </Link>
            <Menu
              theme="dark"
              selectedKeys={selectedKey ? [selectedKey] : undefined}
              className="menu"
            >
              {MENU_LIST.map((item) => (
                <Menu.Item key={item.key} onClick={() => setSelectedKey(item.key)}>
                  <Link to={item.path}>{item.text}</Link>
                </Menu.Item>
              ))}
            </Menu>
           <AuthUserInfo />
          </Drawer>
        </div>
      ) : (
        <PCHeader selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
      )}
    </>
  );
};

export default Header;
