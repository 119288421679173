import Card from 'antd/es/card/Card';
import BotCard from 'bot/components/BotCard';
import { Bot } from 'bot/models';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface UserSessionPaneProps {
  type: BotRecordPaneKey;
  title: string;
  bots: Bot[];
}

const DefaultNotion = () => (
  <div>
    还没有记录，去<Link to="/bot/center">助手中心</Link>寻找你需要的助手，或者开始与ChatGPT的
    <Link to="/bot/playground">快速体验</Link>吧!
  </div>
);

const FavorateEmptyNotion = () => (
  <div>
    还没有记录，去<Link to="/bot/center">助手中心</Link>收藏你需要的助手吧!
  </div>
);

export enum BotRecordPaneKey {
  FAVORATE = 'favorate',
  CREATED = 'created',
  LATEST = 'latest',
  MOST_USED = 'most_used',
}

const BotRecordPane: FunctionComponent<UserSessionPaneProps> = ({ type, title, bots }) => {
  return (
    <Card title={title} className="bot-record-pane">
      {bots.length === 0 &&
        (type === BotRecordPaneKey.FAVORATE ? <FavorateEmptyNotion /> : <DefaultNotion />)}
      <div className="bot-list">
        {bots.map((bot) => (
          <BotCard bot={bot} key={bot.id} />
        ))}
      </div>
    </Card>
  );
};

export default BotRecordPane;
