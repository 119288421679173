import React from 'react';
import { LoadingOutlined, SendOutlined, StopOutlined } from '@ant-design/icons';
import './index.scss';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import { Button } from 'antd';

interface DynamicTextareaProps {
  maxRows: number;
  onSend: () => void;
  loading: boolean;
  stopGeneration: () => void;
  input: string;
  setInput: (input: string) => void;
  showStopGeneration: boolean;
}

const DynamicTextarea: React.FC<DynamicTextareaProps> = ({
  maxRows,
  onSend,
  loading,
  stopGeneration,
  input,
  setInput,
  showStopGeneration
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSend();
      e.preventDefault(); // 阻止插入换行符
    }
  };

  return (
    <div className="dynamic-textarea">
      {showStopGeneration && (
        <Button
          type="default"
          icon={<StopOutlined />}
          onClick={stopGeneration}
          className="stop-generation-btn"
        >
          中止生成
        </Button>
      )}
      <div className={classNames('textarea-container', { disabled: loading })}>
        {loading && <LoadingOutlined />}
        {!loading && (
          <>
            <TextArea
              value={input}
              autoSize={{ minRows: 1, maxRows: 10 }}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              placeholder="请输入内容"
              className="textarea"
              autoFocus={true}
              disabled={loading}
            />
            <button
              className={`send-button ${input ? 'active' : 'disabled'}`}
              disabled={!input}
              onClick={onSend}
            >
              {loading ? <LoadingOutlined /> : <SendOutlined />}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DynamicTextarea;
