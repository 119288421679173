import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducers from 'user/slice';
import botReducers from 'bot/slice';
import adminReducers from 'admin/slice';

export const store = configureStore({
  reducer: {
    user: userReducers,
    bot: botReducers,
    admin: adminReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;