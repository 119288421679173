import React from 'react';
import DynamicTextarea from './DynamicTextArea';
import BotMessageList from './MessageList';
import './index.scss';
import {
  Bot,
  BotSession,
} from 'bot/models';
import Meta from 'antd/es/card/Meta';
import { useChatPane } from 'bot/pages/Workdesk/hooks';
import { Skeleton } from 'antd';
import BotAvatar from '../BotAvatar';

export interface ChatPaneProps {
  bot: Bot | null;
  session: BotSession | null;
}

const ChatPane: React.FC<ChatPaneProps> = ({ bot, session }) => {
  const chatPaneState = useChatPane(bot, session);
  if (!chatPaneState || !bot) {
    return <Skeleton active />;
  }
  const { state, handlers } = chatPaneState;
  const { reply, messages, loading, input } = state;
  const { handleSubmit, stopGeneration, setInput } = handlers;

  return (
    <div className="chat-component">
      {/* <Meta
        avatar={<img src={bot.avatar || '/images/logo.png'} alt="bot-avatar" />}
        title={bot.name}
        description={bot.description}
        className="bot-info"
      /> */}
      <Meta avatar={<BotAvatar bot={bot} />} description={bot.guide} className="bot-info" />
      <BotMessageList messages={messages} reply={reply} typing={loading} />
      <DynamicTextarea
        maxRows={4}
        onSend={handleSubmit}
        loading={loading}
        stopGeneration={stopGeneration}
        input={input}
        setInput={setInput}
        showStopGeneration={loading && reply.length > 20}
      />
    </div>
  );
};

export default ChatPane;
