import callApi from 'app/services/api';
import wx from 'weixin-js-sdk';
import { isWechat } from './system';
import config from 'app/config';

export const configWechatShare = async (url: string) => {
  if (!isWechat() || config.isDev) {
    return;
  }
  const data = (
    await callApi({
      endpoint: '/auth/wechat/share_config',
      data: {
        url,
      },
    })
  ).data;

  // 使用获取到的配置信息初始化微信JS-SDK
  wx.config({
    debug: false,
    appId: data.appId,
    timestamp: data.timestamp,
    nonceStr: data.nonceStr,
    signature: data.signature,
    jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
  });
};