import { DeleteOutlined, MessageOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { BotSession } from 'bot/models';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

interface SessionRowProps {
  session: BotSession;
  isActive: boolean;
  handlers: {
    deleteSession: (session: BotSession, isActive: boolean) => void;
  }
}

const SessionRow: React.FC<SessionRowProps> = ({ session, isActive, handlers }) => {
  // updated_at是ms, 转换成YYYY-MM-DD HH:mm:ss
  const updatedAt = new Date(session.updated_at).toLocaleString();

  return (
    <li
      className={classNames('session-row', { active: isActive, new: session.isNew })}
      key={session.id}
    >
      <Link to={`/bot/workdesk?sessionId=${session.id}`} className="session-link">
        <MessageOutlined />
        <div className="session-info">
          <div className="bot-workdesk-sider__session-title">{session.title || '历史会话'}</div>
          <div className="updated-at">{updatedAt}</div>
        </div>
        <div className="session-operations">
          <DeleteOutlined
            onClick={(e) => {
              Modal.confirm({
                title: '删除会话',
                content: '确定要删除该会话吗？',
                okText: '删除',
                cancelText: '取消',
                onOk() {
                  handlers.deleteSession(session, isActive);
                },
              });
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
          />
        </div>
      </Link>
    </li>
  );
};

export default SessionRow;
