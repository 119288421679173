import authReducer from './auth/slice';
import dashboardReducer from './dashboard/slice';
import { combineReducers } from '@reduxjs/toolkit';
import botReducer from './bots/slice';

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  bot: botReducer,
});
