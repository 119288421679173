import React, { FunctionComponent } from 'react';

import LandingLayout from 'app/layouts/LandingLayout';
import Banner from './Banner';
import Services from './Services';

import './index.scss';

const Home: FunctionComponent<{}> = () => {
  return (
    <LandingLayout pageName="JarvisAI - 一站式AI助手平台" className="home-page">
      <Banner />
      <Services />
    </LandingLayout>
  );
};

export default Home;