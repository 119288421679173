import React, { FunctionComponent } from 'react';
import { Bot } from 'bot/models';
import { createTitleSvg } from 'app/utils';

import './index.scss';

interface BotAvatarProps {
  bot: Bot | null;
}

const BotAvatar: FunctionComponent<BotAvatarProps> = ({ bot }) => {
  if (!bot) {
    return null;
  }
  return (
    <div className="bot-avatar">
      {bot.avatar && <img src={bot.avatar} alt={bot.name} />}
      {!bot.avatar && createTitleSvg(bot.name)}
    </div>
  );
};

export default BotAvatar;
