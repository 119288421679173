import React, { useState, useEffect, FunctionComponent } from 'react';
import { Modal, Button, message } from 'antd';
import scriptLoader from 'app/utils/scriptLoader';

import './index.scss';
import { setUserAuthAction, setUserProfileAction } from 'user/slice';
import { useAppDispatch, useAppHistory, useAppSelector } from 'app/hooks';
import { routeConfig } from 'app/routes';
import { isWechat } from 'app/utils/system';
import { getAuthToken } from 'user/authService';
import { WechatOutlined } from '@ant-design/icons';

const LoginBtn: FunctionComponent<{}> = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [allowClose, setAllowClose] = useState(true);
  const dispatch = useAppDispatch();
  const currentPath = window.location.pathname;
  const history = useAppHistory();
  const isLogin = useAppSelector((state) => state.user.auth.isLogin);
  const isLoginLoading = useAppSelector((state) => state.user.auth.isLoading);
  const token = getAuthToken();

  useEffect(() => {
    // 检查当前路径是否是需要登录的路径， 如果是，则弹出登录框
    const config = routeConfig.find((item) => item.path === currentPath);
    // 如果需要登录，不是已经登录且没有正在登录才出发自动登录弹框和跳转
    if (config && config.loginRequired && !isLogin && !isLoginLoading && !token) {
      if (isWechat()) {
        mobileWechatAppAuth();
      } else {
        setAllowClose(false);
        setModalVisible(true);
      }
    }
  }, [currentPath, isLogin, isLoginLoading, token]);

  useEffect(() => {
    if (isModalVisible) {
      if (!isWechat()) {
        scriptLoader({
          src: 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js',
        }).then(() => {
          // 替换为你自己的 appid, scope, redirect_uri, state, style, href
          new window.WxLogin({
            self_redirect: true,
            id: 'login_container',
            appid: 'wx3c9631f9e71a56ff',
            scope: 'snsapi_login,snsapi_userinfo',
            redirect_uri: encodeURIComponent(
              'https://api.chatwithjarvis.com/auth/wechat/web_login'
            ),
            state: 'wechat_login',
            style: 'black',
          });
        });
      }
    }
  }, [isModalVisible]);

  const openModal = () => {
    setAllowClose(true);
    setModalVisible(true);
  };

  const onLoginClick = () => {
    if (isWechat()) {
      mobileWechatAppAuth();
    } else {
      openModal();
    }
  };

  // 监听postMessage出来的数据
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data && event.data.type === 'wechat_login_success') {
        const { data } = event.data;
        if (data) {
          setModalVisible(false);
          message.success('登录成功');
          dispatch(setUserAuthAction({ token: data.token, isLogin: true }));
          dispatch(setUserProfileAction({ nickname: data.nickname, avatar: data.avatar_url }));
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <div className="login-btn-container">
      <Button type="primary" onClick={onLoginClick} className="login-btn">
        登录
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={() => {
          if (allowClose) {
            setModalVisible(false);
          } else {
            history.goBack();
          }
        }}
        closable={allowClose}
        footer={null}
        className="wechat-login-modal"
        maskClosable={allowClose}
      >
        <div id="login_container" />
      </Modal>
    </div>
  );
};

export function mobileWechatAppAuth() {
  // 如果url中已经有code, 则不需要再次授权
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  if (code && state === 'wechat_login') {
    return;
  }
  Modal.confirm({
    title: '请先登录',
    content: '无需注册，微信登录后即可免费享受服务，不会涉及手机号、通讯录等隐私信息',
    icon: <WechatOutlined />,
    cancelButtonProps: { style: { display: 'none' } },
    onOk: () => {
      //https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect
      const appId = 'wxd208106ea565f1eb';
      const redirectUrl = encodeURIComponent(window.location.href);
      // const redirectUrl = encodeURIComponent('https://chatwithjarvis.com');
      const scope = 'snsapi_userinfo';
      const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=wechat_login#wechat_redirect`;
      window.location.href = wechatUrl;
    },
    okText: (
      <>
        <WechatOutlined /> 微信登录
      </>
    ),
  });
}

export default LoginBtn;
