import callApi from 'app/services/api';
import { LoginResponse, UserProfile, UserSession } from './models';
import { Bot } from 'bot/models';

export const fetchUserProfile = async () => {
  return callApi<UserProfile>({
    endpoint: `/user/profile`,
    method: 'GET',
  });
};

export const setUserProfile = async (data: UserProfile) => {
  return callApi<UserProfile>({
    endpoint: `/user/profile`,
    method: 'POST',
    data,
  });
};

export const fetchCurrentUser = async () => {
  return callApi<LoginResponse>({
    endpoint: `/auth/current_user`,
    method: 'GET',
  });
};

// 在微信内部的webview登录，使用公众号登录
export const mobileWechatLogin = async (code: string) => {
  return callApi<LoginResponse>({
    endpoint: '/auth/wechat/gzh_login',
    method: 'GET',
    data: {
      code,
    },
  });
}

export interface UserDashboard {
  created_bots: Bot[];
  favorite_bots: Bot[];
  latest_bots: Bot[];
  latest_sessions: UserSession[];
  most_used_bots: Bot[];
}

export const fetchUserDashboard = async () => {
  return callApi<UserDashboard>({
    endpoint: `/user/dashboard`,
    method: 'GET',
  });
};

export const logout = async () => {
  return callApi({
    endpoint: `/auth/logout`,
    method: 'POST',
  });
}
