import React, { ReactNode, useEffect } from 'react';

import { Layout } from 'antd';

import classNames from 'classnames';
import Header from 'app/components/Header';

import './index.scss';
import Footer from 'app/components/Footer';

type PageProps = {
  pageName: string;
  children: ReactNode;
  className?: string;
};

export default function LandingLayout({ pageName, children, className }: PageProps) {
  useEffect(() => {
    document.title = pageName;
  }, []);

  return (
    <Layout className={classNames('landing-layout', className)}>
      <Header />
      {children}
      <Footer />
    </Layout>
  );
}
