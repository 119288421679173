import { UserProfile } from './models';
import { createSlice } from '@reduxjs/toolkit';
import { LoginParams, LoginResult, fetchCurrentUser, login } from './api';
import { UserAuth } from 'user/models';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';
import { logout, remember } from 'user/authService';

interface AuthState extends UserAuth {
  profile?: Pick<UserProfile, 'nickname' | 'avatar'>;
}

const initialState: AuthState = {
  isLoading: false,
  isLogin: false,
};

// Thunks
export const loginThunk = createAppAsyncThunk<LoginResult, LoginParams>(
  'auth/login',
  async (params) => {
    return (await login(params)).data;
  }
);

export const fetchCurrentUserThunk = createAppAsyncThunk<LoginResult>(
  'auth/fetchCurrentUser',
  async () => {
    return (await fetchCurrentUser()).data;
  },
  {
    condition: (_, { getState }) => {
      const { isLogin } = getState().admin.auth;
      return !isLogin;
    }
  }
);

const handleLogin = (state: AuthState, action: any) => {
  if (!action.payload) {
    return;
  }
  if (!action.payload.is_admin) {
    return;
  }
  state.isLogin = true;
  state.token = action.payload.token;
  state.profile = {
    nickname: action.payload.nickname,
    avatar: action.payload.avatar,
  };
  remember(action.payload.token);
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutAction(state) {
      state.isLogin = false;
      state.profile = undefined;
      logout();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      handleLogin(state, action);
    });
    builder.addCase(fetchCurrentUserThunk.fulfilled, (state, action) => {
      handleLogin(state, action);
    });
    builder.addCase(loginThunk.rejected, (state) => {
      state.isLogin = false;
      state.profile = undefined;
      logout();
    });
    builder.addCase(fetchCurrentUserThunk.rejected, (state) => {
      state.isLogin = false;
      state.profile = undefined;
      logout();
    });
  },
});

export const { logoutAction } = authSlice.actions;

export default authSlice.reducer;
