import React from 'react';
import { Router } from 'react-router';
import { Route, Switch, RouteProps } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import * as appPages from 'app/pages';
import * as botPages from 'bot/pages';
import * as userPages from 'user/pages';

const AdminRoutes = React.lazy(() => import('admin/pages/AdminRouters'));

interface MyRouteProps extends RouteProps {
  routeComponent?: React.ComponentType<MyRouteProps>;
  loginRequired?: boolean;
}

const history = createHistory();

export const routeConfig: MyRouteProps[] = [
  {
    path: '/',
    exact: true,
    component: appPages.Home,
  },
  {
    path: '/bot/center',
    exact: true,
    component: botPages.Center,
  },
  {
    path: '/bot/playground',
    exact: true,
    component: botPages.Playground,
    loginRequired: true,
  },
  {
    path: '/bot/workdesk',
    exact: true,
    component: botPages.Workdesk,
    loginRequired: true,
  },
  {
    path: '/user/dashboard',
    exact: true,
    component: userPages.UserDashboard,
    loginRequired: true,
  },
  {
    path: '/contact',
    exact: true,
    component: appPages.Contact,
  },
  {
    path: '/admin',
    component: AdminRoutes,
  }
];

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routeConfig.map((route) => {
          const RouteComponent = route.routeComponent || Route;

          return <RouteComponent key={route.path as string} {...route} />;
        })}
      </Switch>
    </Router>
  );
}
