import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCurrentUser, fetchUserProfile, setUserProfile, logout, fetchUserDashboard, UserDashboard, mobileWechatLogin } from './api';
import { LoginResponse, UserAuth, UserProfile } from './models';
import * as authService from './authService';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';

interface UserState {
  auth: UserAuth;
  profile: UserProfile;
  dashboard: UserDashboard;
}

const initialState: UserState = {
  profile: {},
  auth: {
    isLoading: false,
    isLogin: false,
  },
  dashboard: {
    created_bots: [],
    favorite_bots: [],
    latest_bots: [],
    latest_sessions: [],
    most_used_bots: [],
  },
};

export const fetchUserProfileThunk = createAsyncThunk<UserProfile>(
  'user/fetchUserProfile',
  async () => {
    return (await fetchUserProfile()).data;
  }
);

export const setUserProfileThunk = createAsyncThunk<UserProfile, UserProfile>(
  'user/setUserProfile',
  async (data) => {
    return (await setUserProfile(data)).data;
  }
);

export const fetchCurrentUserThunk = createAppAsyncThunk<LoginResponse>(
  'user/fetchCurrentUser',
  async () => {
    return (await fetchCurrentUser()).data;
  },
  {
    condition: (_, { getState }) => {
      const { isLoading, isLogin } = getState().user.auth;
      return !isLoading && !isLogin;
    }
  }
);

export const logoutThunk = createAsyncThunk('user/logout', async () => {
  return (await logout()).isOK;
});

export const fetchUserDashboardThunk = createAppAsyncThunk('user/fetchUserDashboard', async () => {
  return (await fetchUserDashboard()).data;
}, {
  condition: (_, { getState }) => {
    // 如果没有登录，就不需要获取dashboard
    const { isLogin } = getState().user.auth;
    return isLogin;
  }
});

export const wechatGZHLoginThunk = createAppAsyncThunk<LoginResponse, string>(
  'user/wechatGZHLogin',
  async (code) => {
    return (await mobileWechatLogin(code)).data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuthAction(state, action) {
      authService.remember(action.payload.token);
      state.auth = action.payload;
    },
    setUserProfileAction(state, action) {
      state.profile = action.payload;
    },
    autoLoginFailedAction(state) {
      state.auth = {
        isLoading: false,
        isLogin: false,
      };
      authService.logout();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfileThunk.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    builder.addCase(setUserProfileThunk.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    builder.addCase(fetchCurrentUserThunk.pending, (state) => {
      state.auth.isLoading = true;
    });
    builder.addCase(fetchCurrentUserThunk.fulfilled, (state, action) => {
      const data = action.payload;
      state.auth = {
        isLogin: true,
        isLoading: false,
        token: data.token,
      };
      state.profile = {
        nickname: data.nickname,
        avatar: data.avatar_url,
      };
      authService.remember(data.token);
    });
    builder.addCase(fetchCurrentUserThunk.rejected, (state, action) => {
      if (!action.payload && action.error.name !== 'ConditionError') {
        state.auth.isLoading = false;
        authService.logout();
      }
    });
    builder.addCase(logoutThunk.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      authService.logout();
      state.auth = {
        isLoading: false,
        isLogin: false,
      };
      state.profile = {};
    });
    builder.addCase(fetchUserDashboardThunk.fulfilled, (state, action) => {
      // latest_sessions中created_at是ms级别的时间戳，需要转换成ISO格式
      action.payload.latest_sessions.forEach((session) => {
        session.updated_at = new Date(session.updated_at).toLocaleString();
      });
      state.dashboard = action.payload;
    });
    builder.addCase(wechatGZHLoginThunk.fulfilled, (state, action) => {
      state.auth = {
        isLogin: true,
        isLoading: false,
        token: action.payload.token,
      };
      state.profile = {
        nickname: action.payload.nickname,
        avatar: action.payload.avatar_url,
      };
      authService.remember(action.payload.token);
    });
    builder.addCase(wechatGZHLoginThunk.pending, (state) => {
      state.auth.isLoading = true;
    });
    builder.addCase(wechatGZHLoginThunk.rejected, (state) => {
      state.auth.isLoading = false;
      // 登录失败跳转到首页
      window.location.href = '/';
    });
  },
});

export const { setUserProfileAction, setUserAuthAction, autoLoginFailedAction } = userSlice.actions;

export default userSlice.reducer;
