import { Bot } from 'bot/models';
import React, { FunctionComponent } from 'react';

import './index.scss';
import { useAppHistory } from 'app/hooks';
import { useChatURL } from 'admin/bots/components/BotForm/hooks';
import BotAvatar from '../BotAvatar';
import classNames from 'classnames';
import { Tag, Tooltip, Grid } from 'antd';

const { useBreakpoint } = Grid;

interface BotCardProps {
  bot: Bot;
}

const CardBody = ({ bot }: { bot: Bot }) => {
  const history = useAppHistory();
  const chatURL = useChatURL({ botId: bot.id });
  const disabled = bot.status === 'PENDING';
  const screens = useBreakpoint();
  return (
    <div
      className={classNames('bot-card', {
        disabled,
      })}
      onClick={() => {
        !disabled && history.push(chatURL);
      }}
    >
      <BotAvatar bot={bot} />
      <div className="bot-info">
        <div className="bot-name">
          {bot.name}
          {disabled && !screens.xs && (
            <Tag color="#f50" className="comming-soon-tag">
              即将上线
            </Tag>
          )}
        </div>
        <div className="bot-description">{bot.description}</div>
      </div>
      {disabled && screens.xs && (
        <Tag color="#f50" className="comming-soon-tag">
          即将上线
        </Tag>
      )}
    </div>
  );
};

const BotCard: FunctionComponent<BotCardProps> = ({ bot }) => {
  return <CardBody bot={bot} />;
};

export default BotCard;
