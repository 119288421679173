import { createSlice } from '@reduxjs/toolkit';
import { BotFormConfig } from './models';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';
import { fetchBotFormConfig } from './api';

interface AdminBotState {
  formConfig: BotFormConfig & {
    loadding: boolean;
  };
}

const initialState: AdminBotState = {
  formConfig: {
    loadding: false,
    status: [],
    scope: [],
    type: [],
  },
};

export const fetchBotFormConfigThunk = createAppAsyncThunk<BotFormConfig>(
  'adminBot/fetchBotFormConfig',
  async () => {
    return (await fetchBotFormConfig()).data;
  },
  {
    condition: (_, { getState }) => {
      const formConfig = getState().admin.bot.formConfig;
      // 如果正在加载中或者已经有数据了，就不再发起请求
      return !formConfig.loadding && !formConfig.status.length;
    },
  }
);

const adminBotSlice = createSlice({
  name: 'adminBot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBotFormConfigThunk.pending, (state) => {
      state.formConfig.loadding = true;
    });
    builder.addCase(fetchBotFormConfigThunk.fulfilled, (state, action) => {
      state.formConfig = {
        ...action.payload,
        loadding: false,
      };
    });
  },
});

export default adminBotSlice.reducer;
