import React, { FunctionComponent, useEffect } from 'react';
import './App.scss';
import Routes from 'app/routes';
import { useAutoLogin, useWechatLogin } from 'user/hooks';

const App: FunctionComponent = () => {
  useAutoLogin();
  useWechatLogin();
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
