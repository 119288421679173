import React, { useState } from 'react';
import { Bot, BotSession } from 'bot/models';

import { Button, Empty, Skeleton, Drawer, Grid } from 'antd';

import './index.scss';
import SessionRow from './SessionRow';
import { MenuOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { useSider } from 'bot/pages/Workdesk/hooks';
import { Link } from 'react-router-dom';
import AuthUserInfo from 'user/components/AuthUserInfo';

const { useBreakpoint } = Grid;

interface SiderProps {
  bot: Bot | null;
  session: BotSession | null;
  mobile?: boolean;
}

const PCWorkdeskSider: React.FC<SiderProps> = ({ bot, session, mobile }) => {
  const { state, handlers } = useSider();
  const { sessions } = state;

  return (
    <div className="bot-workdesk-sider">
      {!bot && <Skeleton active />}
      {bot && (
        <>
          <div className="bot-workdesk-sider__header">
            <div className="bot-name">
              <span>{bot && bot.name}</span>
              <Link to={`/bot/center`}>
                <SwapOutlined />
              </Link>
            </div>
            {!mobile && (
              <Button type="default" className="new-session-btn" onClick={handlers.newSession}>
                <PlusOutlined />
              </Button>
            )}
          </div>
          <ol className="bot-workdesk-sider__sessions">
            {sessions &&
              sessions.map((_session) => (
                <SessionRow
                  key={_session.id}
                  session={_session}
                  isActive={session ? _session.id === session.id : false}
                  handlers={{
                    deleteSession: handlers.deleteSession,
                  }}
                />
              ))}
            {(!sessions || sessions.length === 0) && <Empty description={false} />}
          </ol>
        </>
      )}
      <AuthUserInfo />
    </div>
  );
};

const MobileWorkdeskSider: React.FC<SiderProps> = ({ bot, session }) => {
  const [visible, setVisible] = useState(false);
  const { state, handlers } = useSider();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="mobile-sider-header">
      <Button type="ghost" className="trigger-btn" onClick={showDrawer} icon={<MenuOutlined />} />
      <div className="session-name">{state.session ? state.session.title : '新会话'}</div>
      <Button type="ghost" className="new-session-btn" onClick={handlers.newSession}>
        <PlusOutlined />
      </Button>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
        className="mobile-header"
        width={200}
      >
        <PCWorkdeskSider bot={bot} session={session} mobile={true} />
      </Drawer>
    </div>
  );
};

const WorkdeskSider: React.FC<SiderProps> = ({ bot, session }) => {
  const screens = useBreakpoint();

  return (
    <>
      {screens.xs ? (
        <MobileWorkdeskSider bot={bot} session={session} />
      ) : (
        <PCWorkdeskSider bot={bot} session={session} />
      )}
    </>
  );
};

export default WorkdeskSider;
