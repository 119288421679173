export interface Bot {
  id: string;
  name: string;
  created_at: string;
  avatar: string;
  description: string;
  guide: string;
  config: object;
  status: 'ONLINE' | 'PENDING';
}

export enum ContentRole {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export interface BotSessionMessageContent {
  role: ContentRole;
  content: string;
}

export interface BotSessionMessage {
  content: BotSessionMessageContent;
  created_at?: string;
  type: string;
}

export interface BotSession {
  id: string;
  title: string;
  bot: Bot;
  messages: BotSessionMessage[];
  created_at: string;
  updated_at: string;
  isNew: boolean;
}
