import React, { FunctionComponent, useEffect } from 'react';
import Layout, { Content } from 'antd/es/layout/layout';
import Header from 'app/components/Header';
import classNames from 'classnames';

import './index.scss';
import { Col, Row } from 'antd';
import Footer from 'app/components/Footer';

interface BaseLayoutProps {
  pageName: string;
  className: string;
  children: React.ReactNode;
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({ pageName, className, children }) => {
  useEffect(() => {
    document.title = pageName;
  }, []);

  return (
    <Layout className={classNames('base-layout', className)}>
      <Header />
      <Content>
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col xs={24} sm={22} md={20} lg={18} xl={16}>
            <div className="content-wrapper">
              {children} {/* 这里是内容部分 */}
            </div>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};

export default BaseLayout;
