export interface ScriptOptions {
  src: string;
  async?: boolean;
  defer?: boolean;
  attributes?: { [key: string]: string };
}

export default function loadScript(options: ScriptOptions): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const { src, async, defer, attributes } = options;

    const script = document.createElement('script');
    script.src = src;
    script.async = async ?? true;
    script.defer = defer ?? true;

    if (attributes) {
      Object.entries(attributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }

    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      reject(new Error(`Failed to load script: ${src}`));
    };

    document.head.appendChild(script);
  });
}