import { createSlice } from '@reduxjs/toolkit';
import { Bot } from 'bot/models';
import { fetchBotList } from 'bot/api';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';

type BotListState = Bot[];

const initialState: BotListState = [];

/**
 * 获取助手列表
 */
export const fetchBotListThunk = createAppAsyncThunk<Bot[]>('bot/fetchBotList', async () => {
  return (await fetchBotList()).data;
});

const slice = createSlice({
  name: 'bot/list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBotListThunk.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
    builder.addCase(fetchBotListThunk.rejected, (state, action) => {
      state = [];
      return state;
    });
  },
});

export default slice.reducer;
