import React, { FunctionComponent } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import { getChildrenToRender } from '../utils';
import { CONFIG } from './config';

import './index.scss';

const Services: FunctionComponent<{}> = () => {
  const { wrapper, titleWrapper, page, OverPack: overPackData, childWrapper } = CONFIG;
  return (
    <div {...wrapper}>
      <div {...page}>
        <div {...titleWrapper}>{titleWrapper.children.map(getChildrenToRender)}</div>
        <OverPack {...overPackData}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            componentProps={childWrapper}
          >
            {childWrapper.children.map((block, i) => {
              const { children: item, ...blockProps } = block;
              return (
                <Col key={i.toString()} {...blockProps}>
                  <div {...item}>{item.children.map(getChildrenToRender)}</div>
                </Col>
              );
            })}
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};

export default Services;
