import React from 'react';
import { useUserState } from 'user/hooks';
import { Avatar as AntAvatar } from 'antd';

const Avatar = () => {
  const userState = useUserState();

  return <AntAvatar src={userState.profile.avatar} className="user-avatar" />;
};

export default Avatar;
