import { useAppDispatch, useAppHistory, useAppSelector } from 'app/hooks';
import { useCallback, useEffect } from 'react';
import { fetchCurrentUserThunk, logoutThunk, wechatGZHLoginThunk } from './slice';
import { getAuthToken } from './authService';
import { isWechat } from 'app/utils/system';
import { removeQueryParameters } from 'app/utils';

export const useAutoLogin = () => {
  const dispatch = useAppDispatch();
  const token = getAuthToken();
  useEffect(() => {
    if (token) {
      dispatch(fetchCurrentUserThunk());
    }
  }, [token, dispatch]);
};

export const useWechatLogin = () => {
  const dispatch = useAppDispatch();
  const history = useAppHistory();
  const code = new URLSearchParams(window.location.search).get('code');
  const isLogin = useAppSelector((state) => state.user.auth.isLogin);

  useEffect(() => {
    // 处理微信内置浏览器的授权等，授权后，页面进行跳转，这里需要处理跳转后的逻辑
    if (isWechat() && !isLogin && code) {
      dispatch(wechatGZHLoginThunk(code)).then((resp) => {
        if (!resp.payload) {
          // 登录失败，返回首页
          history.push('/');
        } else {
          removeQueryParameters(['code', 'state']);
        }
      });
    }
  }, [code, isLogin]);
};

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const history = useAppHistory();

  const logoutHandler = useCallback(() => {
    // 先跳转到首页后退出
    history.push('/');
    dispatch(logoutThunk());
  }, []);

  return logoutHandler;
};

export const useUserState = () => {
  return useAppSelector((state) => state.user);
};
