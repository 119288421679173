type EventCallback = (data: string) => void;
type ErrorCallback = (error: any) => void;

interface SSEProps {
  url: string;
  data: Record<string, any>;
  onNewData: EventCallback;
  onConnectionClosed: EventCallback;
  onError?: ErrorCallback;
}

export interface SSEConnection {
  close: () => void;
}

export default function createPostSSE({
  url,
  data,
  onNewData,
  onConnectionClosed,
  onError,
}: SSEProps): SSEConnection {
  const xhr = new XMLHttpRequest();

  xhr.open('POST', url, true);

  // 设置Content-Type为text/event-stream
  xhr.setRequestHeader('Content-Type', 'text/event-stream');

  // 设置回调来处理来自服务器的数据
  xhr.onprogress = function () {
    const responseText = xhr.responseText;
    const newData = responseText.substring((xhr as any).lastIndex || 0);
    (xhr as any).lastIndex = responseText.length;

    // 这里处理新收到的数据
    if (onNewData) {
      try {
        onNewData(newData);
      } catch (e) {
        console.error(e);
        console.log(newData);
      }
    }
  };

  // 如果连接关闭，则可以处理这个事件
  xhr.onerror =
  xhr.onload =
    function () {
      if (onConnectionClosed) {
        onConnectionClosed('Connection closed');
      }
    };

  // 处理错误
  xhr.onerror = function (ev) {
    if (onError) {
      onError(ev);
    }
  };

  // 发送请求
  xhr.send(JSON.stringify(data));

  return {
    close: () => {
      xhr.abort();
    },
  };
}
